// Vendor
import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'next/router';
import cn from 'classnames';
import { actions } from 'state/modal/actions';

// Components
import Button from 'components/Button';
import ModalSearch from 'components/Modals/ModalSearch';
import Articles from './Articles';
import Menu from './Menu';
import Profile from './Profile';

// hooks
import useAnalytics from 'hooks/useAnalytics';
import { headerButtonSelector } from 'state/customization/selectors';

import styles from './styles.styl';

const DEFAULT_STATE = 'Articles';
const SUBMENUS_ALL = {
  id: 1999,
  name: 'All Articles',
  slug: 'all',
};

const Header = ({ className, router, pages, categories, isTablet, isPhone, openModal }) => {
  const metasiteButton = useSelector(headerButtonSelector);
  const { elementClick } = useAnalytics();
  const pagesLinksRef = useRef();

  const allCategories = [SUBMENUS_ALL, ...categories];
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [innerCategories, setInnerCategories] = useState(allCategories);
  const [categoryType, setCategoryType] = useState('');

  const toggleMenu = useCallback(() => {
    setIsShowMenu(!isShowMenu);
  }, [isShowMenu]);

  const openSearch = useCallback(() => {
    elementClick('80lv_site_search_click');
    openModal({
      modalType: ModalSearch,
      modalProps: {
        hasClose: false,
        theme: 'search',
      },
      lockOptions: {
        blured: true,
      },
    });
  }, [elementClick, openModal]);

  useEffect(() => {
    var shortRoute = router.asPath
      .split('#')[0]
      .replace(/\/?\?.*/g, '')
      .replace(/\/?/g, '');
    pages.map(page => {
      if (page.link === `/${shortRoute}`) {
        if (page.children) {
          if (page.id === 1) {
            setInnerCategories(allCategories);
            setCategoryType(DEFAULT_STATE);
          } else {
            setInnerCategories(page.children);
            setCategoryType(page.name);
          }
        } else {
          setInnerCategories([]);
          setCategoryType(page.name);
        }
      } else if (page.children && page.children.length > 0) {
        page.children.map(pagechild => {
          if (pagechild.link === `/${shortRoute}`) {
            setInnerCategories(page.children);
            setCategoryType(page.name);
          }
          return pagechild;
        });
      }
      return page;
    });
  }, []);

  const metasiteButtonEl = useMemo(
    () => (
      <a
        style={{
          '--metasite-bg-color': metasiteButton.background_color,
          '--metasite-text-color': metasiteButton.color,
        }}
        className={cn(styles.Header__orderOutsourcing, styles.Header__buyMetasite)}
        href={metasiteButton.href}
        target="_blank"
        rel="noreferrer"
      >
        {metasiteButton.text}
      </a>
    ),
    [metasiteButton],
  );

  return (
    <header className={cn(styles.Header, className)}>
      <div ref={pagesLinksRef} className={styles.Header__topLevel}>
        <div className={cn(styles.Header__pages)}>
          <div
            className={cn(styles.Header__pagesItem, styles.Header__pagesItem__logo80lv)}
            onClick={() => {
              location.href = '/';
            }}
          >
            <img src="/static/icons/logo80lv.svg" alt="logo80lv" />
          </div>
          {!isPhone &&
            !isTablet &&
            pages.map(item => (
              <div
                key={item.id}
                className={cn(styles.Header__pagesItem, {
                  [styles.Header__pagesItem__active]: item.children && categoryType === item.name,
                })}
                onClick={() => {
                  if (item.children) {
                    if (categoryType === item.name) {
                      setInnerCategories([]);
                      setCategoryType('');
                    } else {
                      if (item.id === 1) {
                        setInnerCategories(allCategories);
                        setCategoryType(DEFAULT_STATE);
                      } else {
                        setInnerCategories(item.children);
                        setCategoryType(item.name);
                      }
                    }
                  } else {
                    setInnerCategories([]);
                    setCategoryType(item.name);
                    window.open(item.link, item.targetBlank ? '_blank' : '_self');
                  }
                }}
              >
                {item.name}
                {item.children && (
                  <img
                    src={`/static/icons/arrow-${
                      categoryType === item.name ? 'up-active' : 'down'
                    }.svg`}
                    alt="click_arrow"
                    className={styles.Header__Arrow}
                  />
                )}
              </div>
            ))}
        </div>

        <div className={styles.Header__topRightContainer}>
          <div className={styles.Header__extraButtons}>
            {!isPhone && !isTablet && metasiteButton.isLoaded && metasiteButtonEl}

            {!isPhone && !isTablet && (
              <div
                className={cn(styles.Header__pagesItem, {
                  [styles.Header__pagesItem__active]: '/contact-us/#Promote' === router.asPath,
                })}
                onClick={() => window.open('/contact-us/#Promote', '_self')}
              >
                <span className={styles.Header__advertiseText}>Advertise</span>
                <img
                  width="20"
                  height="20"
                  src="/static/icons/player_new.svg"
                  className={styles.Header__advertiseIcon}
                  alt="player"
                />
              </div>
            )}
          </div>

          {!isPhone && !isTablet && (
            <>
              <Button className={styles.Header__search} theme="none" onClick={openSearch}>
                <img
                  src="/static/icons/search24px.svg"
                  alt="search"
                  className={styles.Header__searchIcon}
                />
              </Button>
              <Profile />
            </>
          )}

          {(isPhone || isTablet) && metasiteButtonEl}

          {(isPhone || isTablet) && (
            <Button className={styles.Header__menuButton} theme="none" onClick={toggleMenu}>
              <img
                src="/static/icons/hamburger_new.svg"
                alt="Menu"
                className={styles.Header__menuIcon}
              />
            </Button>
          )}
        </div>
      </div>

      {innerCategories.length > 0 && categoryType !== '' && !isPhone && !isTablet && (
        <>
          <hr className={styles.Header__divider} />
          <Articles
            className={styles.Header__articles}
            path={router.asPath}
            list={innerCategories}
            type={categoryType}
          />
        </>
      )}

      {(isPhone || isTablet) && (
        <Menu
          pages={pages}
          tags={allCategories}
          path={router.asPath}
          isShow={isShowMenu}
          onClose={toggleMenu}
          orderOutsourcingButton={metasiteButtonEl}
          openSearch={openSearch}
        />
      )}
    </header>
  );
};

Header.defaultProps = {
  className: '',
};

Header.propTypes = {
  className: PropTypes.string,
  router: PropTypes.object,
  pages: PropTypes.array,
  categories: PropTypes.array,
  isTablet: PropTypes.bool,
  isPhone: PropTypes.bool,
  openModal: PropTypes.func,
};

const connector = connect(
  state => ({
    isTablet: state.responsive.isTablet,
    isPhone: state.responsive.isPhone,
  }),
  dispatch => ({
    openModal: modalParams => dispatch(actions.openModal(modalParams)),
  }),
);

export default withRouter(connector(Header));
