import React from 'react';
import dynamic from 'next/dynamic';
import App, { Container } from 'next/app';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import { mediaChanged } from 'redux-mediaquery';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { getCurrentActiveFeatureNames } from '@paralleldrive/feature-toggles';
import { getGdprCookie } from 'utils/gdpr';
import checkOnBot from 'utils/checkOnBot';
import { deviceParser } from 'utils/detectDevice';
import handleResponseStatus from 'utils/handleResponseStatus';
import { features as initialFeatures } from 'utils/features';
import Analytics from 'components/Analytics';

// Actions
import {
  loadCategoriesSuccess,
  loadCategoriesIfNotLoaded,
  loadFeaturedIfNotLoaded,
} from 'state/home/actions';

import { setIsAcceptGdpr } from 'state/gdpr/actions';
import { setIsBot } from 'state/promo/actions';
// Components
import Layout from 'components/Layout';

import Layout80lv from 'components/Layout/Layout80lv';
import LoginProvider from 'features/auth/components/LoginProvider';
import { Auth } from 'features/auth/components/Auth';
import { initStore } from 'state';
import { login } from 'features/auth/state/auth/actions';
import { loadProfile } from 'state/profile/actions';
import { loadHeaderButton } from 'state/customization/actions';

const TypeFormButton = dynamic(() => import('components/TypeFormButton'), {
  loading: () => <></>,
});

class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      isSurveyVisible: false,
    };
  }
  static async getInitialProps({ Component, ctx }) {
    try {
      const { store, isServer, req, res } = ctx;
      const deviceDetect = deviceParser(req);
      const isAcceptGdpr = getGdprCookie(ctx);

      if (
        req &&
        req.cookies &&
        req.cookies.token &&
        store.getState().profile.isLoaded === false &&
        store.getState().profile.isLoading === false
      ) {
        await store.dispatch(login(req.cookies.token));
        await store.dispatch(loadProfile(ctx.req.cookies.token));
      }

      return await handleResponseStatus({
        promise: Promise.all([
          isAcceptGdpr !== undefined && store.dispatch(setIsAcceptGdpr(isAcceptGdpr)),
          checkOnBot(deviceDetect.userAgent) ? store.dispatch(setIsBot(true)) : null, // Проверка пользователя на бота
          isServer ? store.dispatch(mediaChanged({ isServer, ...deviceDetect })) : null,
          res && res.menu_data
            ? store.dispatch(loadCategoriesSuccess(res.menu_data.menu, res.menu_data.tags))
            : store.dispatch(loadCategoriesIfNotLoaded()),
          Component.getInitialProps ? Component.getInitialProps(ctx).catch(e => e) : undefined,
          store.dispatch(loadHeaderButton()),
        ]),
        serverRes: res,
      });
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
    }
  }
  componentDidMount = () => {
    if (this.props.status === 404) this.props.store.dispatch(loadFeaturedIfNotLoaded());
    this.setState({
      isSurveyVisible: !JSON.parse(localStorage.getItem('isHideSurvey')) && window.innerWidth > 768,
    });
    this.props.store.dispatch(mediaChanged({ isServer: false }));
  };

  render() {
    const { Component, pageProps, status, store, router } = this.props;
    const { query } = router;
    const { isSurveyVisible } = this.state;
    return (
      <Container>
        <Provider store={store}>
          <FeatureToggles
            features={getCurrentActiveFeatureNames({
              initialFeatures,
              req: { query },
            })}
          >
            <Analytics>
              <LoginProvider>
                <Layout httpStatus={status}>
                  <Layout80lv httpStatus={status}>
                    <Auth>
                      <Component {...pageProps} />
                      {isSurveyVisible && <TypeFormButton />}
                    </Auth>
                  </Layout80lv>
                </Layout>
              </LoginProvider>
            </Analytics>
          </FeatureToggles>
        </Provider>
      </Container>
    );
  }
}

export default withRedux(initStore)(MyApp);
