import React from 'react';

const ArrowIcon = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_308_1279)">
      <path
        d="M15.991 30.3254C8.09935 30.3337 1.66602 23.917 1.66602 16.0587C1.66602 8.20036 8.02435 1.68369 15.9577 1.66702C23.891 1.65036 30.3243 8.06703 30.3243 16.017C30.3243 23.967 23.916 30.3254 15.991 30.3254ZM3.66602 15.992C3.67435 22.817 9.17435 28.3337 15.9743 28.3254C17.6204 28.3687 19.2585 28.082 20.7919 27.4822C22.3254 26.8823 23.7232 25.9815 24.903 24.8328C26.0827 23.6841 27.0205 22.3108 27.661 20.7938C28.3016 19.2769 28.6318 17.6471 28.6324 16.0005C28.6329 14.3539 28.3038 12.7238 27.6643 11.2064C27.0248 9.68907 26.0879 8.31513 24.9089 7.16563C23.73 6.01613 22.3328 5.11434 20.7997 4.51347C19.2666 3.91259 17.6288 3.62479 15.9827 3.66702C9.16601 3.67536 3.65768 9.19202 3.66602 15.992Z"
        fill="currentColor"
      />
      <path
        d="M18.8003 16.6918C19.4836 18.0668 20.1669 19.4085 20.8169 20.7668C20.9325 20.9961 20.9843 21.2523 20.9669 21.5085C20.9413 21.7125 20.8489 21.9024 20.7043 22.0485C20.5596 22.1947 20.3707 22.289 20.1669 22.3168C19.7003 22.3835 19.3086 22.1835 19.0836 21.7418C18.6086 20.8085 18.1503 19.8668 17.6753 18.9251C17.3919 18.3501 17.1086 17.7668 16.8086 17.2001C16.7811 17.1504 16.7429 17.1074 16.6968 17.0743C16.6507 17.0411 16.5978 17.0187 16.5419 17.0085C15.3753 16.9918 14.2086 17.0001 13.0003 17.0001V17.3335C13.0003 18.6251 13.0003 19.9168 12.9919 21.2085C12.9919 21.8751 12.5919 22.3251 12.0003 22.3251C11.4086 22.3251 11.0003 21.8751 11.0003 21.2168C10.9947 17.7335 10.9947 14.2557 11.0003 10.7835C11.0003 10.0585 11.3836 9.66679 12.1169 9.66679C13.8336 9.66679 15.5586 9.65846 17.2753 9.66679C18.9919 9.67512 20.4836 10.7918 20.9003 12.5168C21.3169 14.2418 20.5003 15.8001 18.9503 16.6168L18.8003 16.6918ZM13.0086 14.9918C14.5253 14.9918 16.0086 15.0001 17.4919 14.9835C17.9503 14.9751 18.3336 14.7418 18.6169 14.3835C19.0336 13.8501 19.1253 13.2668 18.8503 12.6501C18.5753 12.0335 18.0669 11.6835 17.3753 11.6751C15.9669 11.6585 14.5669 11.6668 13.1586 11.6668C13.1081 11.6717 13.058 11.6801 13.0086 11.6918V14.9918Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_308_1279">
        <rect width="32" height="32" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowIcon;
